.styles {
  :global {
    .link {
      display: flex;
      justify-content: space-around;
      padding-top: 52px;
      .follow-us,
      .official {
        margin-right: 29px;
        p {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */
          text-align: left;
          color: #000000;
        }
        .follow-item {
          display: flex;
          img {
            width: 36px;
            height: 36px;
            cursor: pointer;
            &:not(:last-child) {
              margin-right: 18px;
            }
          }
        }
      }
      .follow-us,
      .official,
      .Policy {
        // width: 336px;
        // height: 150px;
        a {
          display: block;
          text-decoration: underline;
        }
        p,
        a {
          color: #000000;
          margin-bottom: 5px;
        }
      }
      .official {
        text-align: center;
        p {
          text-align: center;
        }
        &-item {
          margin-left: 5px;
          img {        
            width: 48px;
            height: 36px;
            &:not(:first-child) {
              margin-left: 30px;
            }
          }
        }
      }
      .Policy {
        text-align: right;
      }
    }
    @media (max-width: 768px) {
      .link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .follow-us {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 0;
          img {
            width: 48px;
            height: 36px;
            &:not(:last-child) {
              margin-right: 18px;
            }
          }
        }
        .official {
          margin-top: 41px;
          margin-right: 0;
        }
        .Policy {
          margin-top: 40px;
          text-align: center;
        }
      }
    }
  }
}
