.styles {
  :global {
    .header-main {
      height: 70px;
      width: 100%;
      display: flex;
      align-items: center;
      color: #fff;
      justify-content: space-between;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      padding: 0 50px;
      position: fixed;
      top: 0;
      z-index: 1000;
      overflow-x: hidden;
      img {
        width: 170px;
        height: 39px;
        cursor: pointer;
      }
      .tools {
        display: flex;
        align-items: center;
        .tab {
          cursor: pointer;
        }
      }
      .language {
        width: 99px;
        height: 35px;
        border: 1px solid #ffffff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    @media (max-width: 768px) {
      .header-main {
        padding: 0 31px 0 25px;
        .icon-style {
          font-size: 25px;
        }
      }
    }
  }
}
