@import 'styles/variables.scss';
.main.ant-layout-content {
  min-height: calc(100vh - 70px);
}
#root .ant-layout {
  background-color: $bg-1;
}
.ant-layout.white {
  background-color: white;
}
.ant-layout.full {
  header {
    display: none;
  }
  .main.ant-layout-content {
    min-height: 100vh;
  }
}