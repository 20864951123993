$app-background: white;
$dark-grey: #171725;
$grey: #2c2f46;
$grey-1: #515571;
$grey-2: #696974;
$grey-3: #b5b5be;
$grey-4: #e7e7ed;
$grey-5: #5f9595;

$sunshine: #faae56;
$light-sunshine: #fff9ec;
$midnight: #2c2f46;
$purple: #9141c7;
$light-purple: rgba(145, 65, 199, 0.12);
$purple-bg: rgba(145, 65, 199, 0.08);

$accent-1: #5f9595;
$accent-2: #4c2c61;
$accent-3: #b3395e;
$accent-4: #6ab9d2;

$neutral-1: #f3eeea;
$neutral-2: #c4d7d1;
$neutral-3: #a9b8bb;

$white: #ffffff;
$bg-1: #fafafb;
$bg-2: #efeff1;
$bg-3: #e5e5e5;

$success: #39b38e;
$success-bg: #effef9;
$error-bg: #fff7f7;
$error: #b33939;
$notice-1: #fac856;
$notice-2: #fc5a5a;
$green-1: #5f9595;
$max-width: 1920px;
$min-width: 1300px;
$header-height: 68px;
$double-header-height: 171px;
$mini-header-height: 48px;
$footer-height: 80px;
$page-padding: 100px;
$base-font: HelveticaNeue,  arial, sans-serif;

// 声调颜色
$tone-1: #fee7e2;
$tone-2: #fef4dd;
$tone-3: #dbf4e3;
$tone-4: #d9effa;
$tone-5: #ebebed;

$tone-color-1: #ff858c;
$tone-color-2: #fac856;
$tone-color-3: #62c1a5;
$tone-color-4: #6ab9d2;
$tone-color-5: #838399;

// %regular {
//   font-family: 'Proxima Nova', #{$base-font};
//   // font-weight: normal;
// }
// %bolder {
//   font-family: 'Proxima Nova semi', #{$base-font};
//   // font-weight: bolder;
// }
// %bold {
//   font-family: 'Proxima Nova bold', #{$base-font};
//   // font-weight: bold;
// }
%regular {
  font-family: 'Inter', #{$base-font};
  font-weight: normal;
}
%bolder {
  font-family: 'Inter', #{$base-font};
  font-weight: 600;
}
%bold {
  font-family: 'Inter', #{$base-font};
  font-weight: 800;
}
%clickable {
  cursor: pointer;
  user-select: none;
}
@mixin flex-box($JC: flex-start, $AI: center, $FD: row, $FW: nowrap, $F: flex) {
  display: $F;
  justify-content: $JC;
  align-items: $AI;
  flex-direction: $FD;
  flex-wrap: $FW;
}
@function ptToPx($px) {
  @return $px * 11 / 10 + px;
}
@function pxToEm($px, $base: 1024) {
  @return ($px / $base) * 1em;
}
%big-dashed-button {
  user-select: none;
  cursor: pointer;
  @extend %bolder;
  border: 0.5px dashed $grey-3;
  border-radius: 4px;
  background-color: white;
  color: $purple;
  border-color: $purple;
  transition: ease background-color 0.3s;
  &:hover {
    background-color: $bg-1;
  }
}
%break-line {
  white-space: pre-wrap;
  word-break: break-word;
}
